<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import TableListComponent from '@/components/TableList/main.vue';

import { employeeMethods, listPaging, masterMethods, checkPermission, checkPermissionName, keyMaster } from '@/state/helpers';
import { nameRoleFixed } from '@/config/var-common.js';
import { truncateText } from '@/utils/format';

export default {
    page: {
        title: 'パルディア社員一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Layout,
        Footer,
        Multiselect,
        TableListComponent
    },
    data() {
        return {
            listData: [],
            listDataMasterTeam: [],
            listDataMasterGroup: [],
            listDataMasterRoles: [],
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: ''
            },
            perPageList: listPaging,
            filter: {
                team: '',
                role: '',
                role_name: ''
            },
            checkShowFilter: false,
            configKeyword: {
                arrayTag: [],
                placeholder: '名前　名前(カナ) ...',
                isShowLable: true
            },
            configTable: {
                headers: [
                    {
                        label: 'No'
                    },
                    {
                        label: this.$t('pageEmployee.name')
                    },
                    {
                        label: this.$t('pageEmployee.role')
                    },
                    {
                        label: this.$t('pageEmployee.team')
                    },
                    {
                        label: this.$t('pageEmployee.email')
                    },
                    {
                        label: this.$t('pageEmployee.cellphoneNumber')
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            },
            isLoading: false
        };
    },
    watch: {},
    mounted() {
        this.getList();
        this.getListMasterGroup();
        this.getListMasterRoles();
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...employeeMethods,
        truncateTextTable(text) {
            return truncateText(text);
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
            } else {
                this.configKeyword.placeholder = '名前　名前(カナ) ...';
            }
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getListMasterRoles() {
            this.listMaster('roles').then((data) => {
                if (!data || data.length <= 0) return;
                this.listDataMasterRoles = data
                    .map((item) => {
                        item.text = nameRoleFixed.find((o) => o.id === item.id)?.text ?? '';
                        return item;
                    })
                    .filter((role) => role.name !== 'partner');
            });
        },
        getListMasterGroup() {
            this.listMaster(keyMaster['employee.department']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        setTeam() {
            this.listDataMasterTeam = [];
            this.filter.team = '';
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.filter.role.id);
            if (i >= 0) {
                this.filter.role_name = this.listDataMasterRoles[i].name;
                if (this.filter.role_name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == 1);
                    this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                }
                if (this.filter.role_name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == 5);
                    this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                }
            }
        },
        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }

                if (this.filter) {
                    let shallCopy = { ...this.filter };
                    shallCopy.role = this.filter.role?.id ?? '';
                    shallCopy.team = this.filter.team?.id ?? '';
                    for (const [key, value] of Object.entries(shallCopy)) {
                        if (key && value) query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listEmployee(query).then((data) => {
                this.listData = data.data;
                this.configPage.total = data._metadata.total;
                this.configPage.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.configPage.page !== 1) {
                    this.configPage.page = 1;
                    this.getList();
                } else {
                    this.isLoading = false;
                }
            });
        },

        search() {
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                kana_name: '',
                email: '',
                phone_number: ''
            };
            this.filter = {
                team: '',
                role: '',
                department: ''
            };
            this.configKeyword.arrayTag = [];
            this.getList();
        },

        handleRemoveSetTeam() {
            this.listDataMasterTeam = [];
            this.filter = {
                team: '',
                role: '',
                department: ''
            };
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">パルディア社員検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom01">{{ $t('pageEmployee.name') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom01" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom02">{{ $t('pageEmployee.name_kana') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom02" v-model="form.kana_name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom03">{{ $t('pageEmployee.email') }}</label>
                                            <div class="w-100">
                                                <input
                                                    id="validationCustom03"
                                                    v-model="form.email"
                                                    autocomplete="off"
                                                    type="email"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom04">{{ $t('pageEmployee.role') }} </label>
                                            <div class="col-sm-6 p-0">
                                                <Multiselect
                                                    :id="`select-role_id-object`"
                                                    :value.sync="filter.role"
                                                    :options="listDataMasterRoles"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'text',
                                                        valueSync: null,
                                                        error: false,
                                                        isRemove: false,
                                                        isRequired: false
                                                    }"
                                                    @select="setTeam()"
                                                    @remove="handleRemoveSetTeam()"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="filter.role_name !== 'part_time' && filter.role_name !== 'administrator'">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.team') }}</label>
                                            <div class="col-sm-6 p-0">
                                                <Multiselect
                                                    :id="`select-role_id-object`"
                                                    :value.sync="filter.team"
                                                    :options="listDataMasterTeam"
                                                    :config="{
                                                        trackBy: 'id',
                                                        label: 'value',
                                                        valueSync: null,
                                                        error: false,
                                                        isRemove: false,
                                                        isRequired: false
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom07">{{ $t('pageEmployee.cellphoneNumber') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom07" v-model="form.phone_number" type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            パルディア社員検索結果 <span>件数（{{ configPage.total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <TableListComponent
                            :config="configTable"
                            :dataList="listData"
                            :isLoading="isLoading"
                            :configPage.sync="configPage"
                            @onChangePage="getList()"
                        >
                            <template #row="{ row, index }">
                                <tr @dblclick="goToEdit('/employee/view', row)">
                                    <td>
                                        <div class="row-data-small">
                                            {{ index }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ truncateTextTable(row.name + (row.name_kana ? row.name_kana : '')) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.role ? $t(row.role.name) : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.team ? row.team.value : '' }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.email }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row-data-small">
                                            {{ row.phone_number }}
                                        </div>
                                    </td>
                                    <td align="center">
                                        <div class="row-data-small">
                                            <a
                                                v-if="checkPermiss(['administrator', 'instructor', 'part_time', 'sale'], '')"
                                                href="javascript:void(0)"
                                                class="text-info border-right-1"
                                                @click="goToEdit('/employee/view', row)"
                                                >{{ $t('btn.detail') }}</a
                                            >
                                            <a
                                                v-if="checkPermiss(['administrator', 'instructor', 'part_time'], '')"
                                                href="javascript:void(0)"
                                                class="text-success"
                                                @click="goToEdit('/employee/form', row)"
                                                >{{ $t('btn.edit') }}</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </TableListComponent>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
