function showMessage(code, bvToast, title, toaster = 'b-toaster-top-center', solid = true) {
    // code 8080: local warning
    switch (code) {
        case 200:
            bvToast.toast('Successs', {
                title: title,
                variant: 'success',
                toaster: toaster,
                solid: solid
            });
            break;
        case 422:
            bvToast.toast('Error', {
                title: title,
                variant: 'danger',
                toaster: toaster,
                solid: solid
            });
            break;
        case 8080:
            bvToast.toast('Warning', {
                title: title,
                variant: 'warning',
                toaster: toaster,
                solid: solid
            });
            break;
        default:
            bvToast.toast('Error', {
                title: title,
                variant: 'danger',
                toaster: toaster,
                solid: solid
            });
    }
}

export { showMessage };
